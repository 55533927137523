// src/components/SubMenu.tsx

import React from "react";
import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";
import { ChevronRight } from "@mui/icons-material";
import {
  StyledArrowIcon,
  DropdownItem,
  DropdownSeparator,
} from "./DropdownMenu.styled";
import { DropdownSubmenuItem, SubMenuContent } from "./DropdownMenu.styled";
import { DropdownMenuItem } from "./DropdownMenu";
import useResponsive from "../../hooks/useResponsive";

interface SubmenuProps {
  item: DropdownMenuItem;
}

export const Submenu: React.FC<SubmenuProps> = ({ item }) => {
  const isMobile = useResponsive();

  return (
    <DropdownMenuPrimitive.Sub>
      <DropdownMenuPrimitive.SubTrigger asChild>
        <DropdownSubmenuItem $isMobile={isMobile}>
          {item.icon && item.icon}
          {item.groupLabel}
          <StyledArrowIcon>
            <ChevronRight />
          </StyledArrowIcon>
        </DropdownSubmenuItem>
      </DropdownMenuPrimitive.SubTrigger>
      <DropdownMenuPrimitive.Portal>
        <SubMenuContent
          side={isMobile ? "bottom" : "right"} // Appear below on mobile
          sideOffset={isMobile ? 2 : 2} // Adjust offset if needed
          align={isMobile ? "start" : "start"} // Alignment remains the same
          alignOffset={isMobile ? 0 : 0}
          avoidCollisions={true} // Enable collision avoidance
          $isMobile={isMobile}
        >
          {item.items?.map((subItem, subIndex) =>
            subItem.separator ? (
              <DropdownSeparator
                key={`sub-separator-${subIndex}`}
                $isMobile={isMobile}
              />
            ) : (
              <DropdownItem
                key={`${item.id}-${subIndex}`}
                onSelect={(event: any) => {
                  if (subItem.keepOpen) {
                    event.preventDefault();
                  }
                  subItem.onSelect?.();
                }}
                $isMobile={isMobile}
              >
                {subItem.icon && subItem.icon}
                {subItem.label}
              </DropdownItem>
            )
          )}
        </SubMenuContent>
      </DropdownMenuPrimitive.Portal>
    </DropdownMenuPrimitive.Sub>
  );
};
