// src/modules/Make/components/MakeProgressTracker/MakeProgressTracker.tsx

import React from "react";
import styled, { css } from "styled-components";
import { ArrowForwardIos as ArrowForwardIcon } from "@mui/icons-material";
import { observer } from "mobx-react-lite";

export const MakeProgressTrackerContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  max-width: 400px;
  // background-color: white;
  // z-index: 50;
  transition: transform 0.2s ease;

  @media (max-width: 500px) {
    transform: scale(0.85);
  }

  @media (max-width: 440px) {
    transform: scale(0.75);
  }
`;

interface StepProps {
  selected: boolean;
  disabled: boolean;
}

export const MakeProgressStepContainer = styled.button<StepProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
  padding: 8px 16px;
  font-family: Lato, sans-serif;
  font-size: 0.9rem;
  font-weight: 550;
  border: none;
  border-radius: 4px;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  background-color: ${({ selected }) => (selected ? "#e0f0ff" : "transparent")};
  color: ${({ selected }) => (selected ? "#003366" : "#555")};
  transition:
    background-color 0.3s,
    color 0.3s;
  min-width: max-content;

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: transparent;
      color: #aaa;
    `}

  &:hover {
    background-color: ${({ selected, disabled }) =>
      disabled ? "transparent" : selected ? "#d0e8ff" : "#f0f0f0"};
  }

  &:focus {
    outline: none;
  }
`;

export const StyledArrowForwardIcon = styled(ArrowForwardIcon)`
  color: #aaa;
  font-size: 1rem !important;
`;

interface MakeProgressTrackerProps {
  currentStep: number;
  goToStep: (step: number) => void;
}

const steps = ["Resume", "Job Description", "Results"];

export const MakeProgressTracker: React.FC<MakeProgressTrackerProps> = observer(
  ({ currentStep, goToStep }) => {
    const handleStepClick = (index: number) => {
      if (index <= currentStep) {
        goToStep(index);
      }
    };

    return (
      <MakeProgressTrackerContainer>
        {steps.map((step, index) => (
          <React.Fragment key={step}>
            <MakeProgressStepContainer
              selected={currentStep === index}
              disabled={index > currentStep}
              onClick={() => handleStepClick(index)}
            >
              {step}
            </MakeProgressStepContainer>
            {index < steps.length - 1 && <StyledArrowForwardIcon />}
          </React.Fragment>
        ))}
      </MakeProgressTrackerContainer>
    );
  }
);
