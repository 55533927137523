// src/App.styled.tsx

import React from "react";
import styled from "styled-components";
import { SVGWrapper } from "./components/primitives";

export const AppHeader = styled.h1`
  margin-top: 8px;
  background-color: #fff;
  color: #1453b8;
  padding: 10px 20px;
  letter-spacing: 1px;
  border-radius: 10px;
  width: 95%;
  max-width: 760px;
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
`;

export const AppSubheader = styled.h5`
  margin-top: -20px;
  padding: 4px 8px;
  letter-spacing: 1px;
  border-radius: 6px;
`;

export const MainContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  width: 100%;
  /* max-width: 1280px; */
  // border: 1px solid #ffffff80;
  border-radius: 10px;
  // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-left: auto;
  margin-right: auto;
  margin-top: 76px;
  padding: 20px;
  box-sizing: border-box;
`;
