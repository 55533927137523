// src/components/TopBar/AccountButton.tsx

import React, { useState, useRef, useEffect, useContext } from "react";
import styled from "styled-components";
import { AccountCircle as AccountCircleIcon } from "@mui/icons-material";
import AppContext from "../../../../contexts/AppContext";
import { observer } from "mobx-react-lite";
import { logoutUser } from "../../../../services/auth/auth";
import {
  AccountContainer,
  DropdownMenu,
  DropdownItem,
  DropdownButton,
  LogoutButton,
  ButtonContainer,
} from "./AccountButton.styled";
import { useFetchUserData } from "../../../../modules/Dashboard/components/UserDisplay/hooks"; // Adjust the import path as needed

// AccountButton Component
const AccountButton: React.FC = observer(() => {
  const { userStore } = useContext(AppContext);

  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Cleanup
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  const email = userStore.email || userStore.testEmail || "No email";

  // Fetch user data using the shared hook
  const userData = useFetchUserData(email);

  return (
    <div
      style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
    >
      <div
        className="top-bar-user"
        style={{
          display: "flex",
          flexDirection: "column",
          fontSize: "12px",
          color: "grey",
          marginRight: "12px",
        }}
      >
        <div>{email}</div>
        <div
          style={{
            fontSize: "0.5rem",
            textTransform: "uppercase",
            marginTop: "2px",
          }}
        >
          Credits Used: {userData ? userData.totalCreditsUsed : "Loading..."}
        </div>
      </div>
      <AccountContainer
        onClick={toggleDropdown}
        ref={dropdownRef}
        aria-label="Account"
      >
        <ButtonContainer>
          <AccountCircleIcon
            style={{
              fill: "grey",
              width: "30px",
              height: "30px",
              transform: "translateY(0px)",
            }}
          />
        </ButtonContainer>
        {isOpen && (
          <DropdownMenu>
            <DropdownItem>{email}</DropdownItem>
            <DropdownItem>
              <strong>Credits Used: </strong>
              {userData ? userData.totalCreditsUsed : "Loading..."}
            </DropdownItem>

            {/* <DropdownButton
              onClick={() => {
                window.open(
                  "https://chromewebstore.google.com/detail/autocv/micjohkmnpfkcnfoifijfdffpcajjiod",
                  "_blank"
                );
              }}
            >
              Install Extension
            </DropdownButton>

            <DropdownButton
              onClick={() => {
                // Define the desired action here
                // window.location.href = "/all-makes"; // Example
              }}
            >
              All Makes
            </DropdownButton>
            <DropdownButton
              onClick={() => {
                // Define the desired action here
                // window.location.href = "/all-makes"; // Example
              }}
            >
              Add Credits
            </DropdownButton> */}
            <LogoutButton
              onClick={() => {
                logoutUser();
              }}
            >
              Logout
            </LogoutButton>
          </DropdownMenu>
        )}
      </AccountContainer>
    </div>
  );
});

export default AccountButton;
