// src/components/TopBar/TopBar.styled.ts

import styled from "styled-components";
import { SVGWrapper } from "../primitives";

export const TopBarContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 20px;
  background-color: white;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.5);
  z-index: 100;
  user-select: none;
  margin-bottom: 0;
`;

export const RunoSVGWrapper = styled(SVGWrapper)`
  svg {
    width: 48px;
    height: 48px;
    fill: ${({ $fill }) => $fill || "#333"};
    /* Uncomment and customize the filter if needed */
    /* filter: invert(); */
  }
`;

// Updated AppLogoContainer to match TopBar height
export const AppLogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: center;
  width: min-content;
  height: 100%; /* Changed from 100px to 100% */
  justify-content: flex-start;

  .top-bar-title {
    // transform: translateX(-20px);
    opacity: 1;
    transition: opacity 0.2s ease;

    @media (max-width: 700px) {
      opacity: 0;
    }
  }
`;

export const TopBarLeft = styled.div`
  display: flex;
  position: absolute;
  left: 0px;
  align-items: center;
  height: 100%; /* Changed from 100px to 100% */
`;

// Styled component for center content
export const TopBarCenter = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  /* Optional: Add styles to define the width or other properties */
  /* width: 200px; */
  /* text-align: center; */
`;

export const TopBarRight = styled.div`
  display: flex;
  position: absolute;
  right: 20px;
  gap: 20px;
  align-items: center;
  justify-content: flex-end;
  height: 100%; /* Changed from 100px to 100% */
`;
