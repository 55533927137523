// src/modules/Dashboard/components/UserDisplay/hooks/useSendUserEmail.ts

import { useEffect } from "react";
import { sendMessageToContentScript } from "../../../../../services/auth/authPostMessage";

export const useSendAuthPostMessage = (userEmail: string | null) => {
  useEffect(() => {
    if (userEmail) {
      sendMessageToContentScript({
        type: "USER_EMAIL",
        email: userEmail,
      });
    }
  }, [userEmail]);
};
