// src/components/TopBar/TopBar.tsx

import React, { useContext } from "react";
import {
  TopBarContainer,
  RunoSVGWrapper,
  AppLogoContainer,
  TopBarLeft,
  TopBarCenter, // Import the styled center component
  TopBarRight,
} from "./TopBar.styled";
import { RunoIconSVG } from "assets/RunoIconSVG";
import AccountButton from "./components/AccountButton/AccountButton";
import AppContext from "../../contexts/AppContext";
import { observer } from "mobx-react-lite";

export const TopBar: React.FC = observer(() => {
  const { userStore } = useContext(AppContext);

  return (
    <TopBarContainer>
      {/* Left-aligned App Logo and Name */}
      <TopBarLeft>
        <AppLogoContainer>
          <RunoSVGWrapper>
            <RunoIconSVG />
          </RunoSVGWrapper>
          <span className="top-bar-title">RunoJobs</span>
        </AppLogoContainer>
      </TopBarLeft>

      {/* Center Content (Portal Div) */}
      <TopBarCenter id="top-bar-center">
        {/* Your center content goes here */}
        {/* For example: */}
        {/* <SearchBar /> */}
      </TopBarCenter>

      <TopBarRight>
        {/* Right-aligned Account Button */}
        {userStore.isAuthenticated && <AccountButton />}
      </TopBarRight>
    </TopBarContainer>
  );
});

export default TopBar;
