// src/components/DropdownMenu.styled.tsx

import styled, { css } from "styled-components";
import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";

// Base Components
const baseContentStyles = css<{ $isMobile: boolean }>`
  box-sizing: border-box;
  border-radius: 6px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  outline: 0.5px solid ${({ theme }) => theme.colors.secondary} !important;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 5px 5px;
  z-index: 500;
  max-height: 80vh; /* Prevent  dropdown from exceeding viewport */
  overflow-y: auto;
`;

const BaseContent = styled(DropdownMenuPrimitive.Content)<{
  $isMobile: boolean;
}>`
  ${baseContentStyles}
`;

const baseItemStyles = css<{ $isMobile: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.black};
  user-select: none;
  outline: none;
  width: 100%;
  border-radius: 4px;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.buttonPrimary};
  }
`;

const BaseItem = styled(DropdownMenuPrimitive.Item)<{ $isMobile: boolean }>`
  ${baseItemStyles}
`;

// Styled Components
export const StyledArrowIcon = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  transform: scale(0.85);
`;

// Dropdown Content
export const DropdownContent = styled(BaseContent)`
  /* Additional styles specific to DropdownContent */
`;

// SubMenu Content with adjusted z-index and additional padding
export const SubMenuContent = styled(DropdownMenuPrimitive.Content)<{
  $isMobile: boolean;
}>`
  ${baseContentStyles}
  z-index: 501; /* Higher than main dropdown */
  font-family: ${({ theme }) => theme.fonts.main};
  margin: 0;
  padding-left: 0;
  min-width: 200px;
  padding: 5px 5px;

  /* Constrain within viewport */
  max-height: 80vh;
  overflow-y: auto;

  /* Position adjustments for mobile */
  ${({ $isMobile }) =>
    $isMobile &&
    css`
      box-shadow: none;
      border: none;
      background-color: ${({ theme }) => theme.colors.white};
      /* Remove manual positioning to let Radix handle it */
      /* Removed: position, top, left, transform */
    `}

  &[data-side="right"] {
    /* Additional styles if needed */
  }

  &[data-side="left"] {
    /* Additional styles if needed */
  }
`;

// Dropdown Item
export const DropdownItem = styled(BaseItem)`
  padding: ${({ $isMobile }) => ($isMobile ? "8px 16px" : "8px 16px")};
  font-size: ${({ $isMobile }) => ($isMobile ? "1rem" : "0.9rem")};

  svg {
    margin-right: 8px;
    fill: ${({ theme }) => theme.colors.secondary};
  }

  /* Styles for items without svg */
  &:not(:has(svg)) {
    padding-left: 10px;
  }

  /* Styles for items with svg */
  &:has(svg) {
    padding-left: 12px;
  }
`;

// Dropdown Submenu Item
export const DropdownSubmenuItem = styled(DropdownMenuPrimitive.SubTrigger)<{
  $isMobile: boolean;
}>`
  ${baseItemStyles}
  padding: ${({ $isMobile }) => ($isMobile ? "6px 10px" : "0px 10px")};
  font-size: 0.9rem;

  /* Optional left icon */
  svg:first-of-type {
    margin-right: 0;
    margin-left: 10px;
  }

  /* Right arrow icon */
  ${StyledArrowIcon} {
    margin-left: auto; /* Pushes the arrow to the far right */
  }
`;

// Dropdown Separator
export const DropdownSeparator = styled.li<{ $isMobile: boolean }>`
  height: 1px;
  background-color: ${({ theme }) => theme.colors.secondary};
  margin: 4px auto;
  list-style: none;
  pointer-events: none;
  width: 90%;
`;
