// src/theme/spacing.ts

const spacing = {
  small: "8px",
  medium: "16px",
  large: "24px",
  // Add more spacing values as needed
};

export default spacing;
