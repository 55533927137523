// src/modules/Make/components/TopBarMake/TopBarMake.tsx

import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { MakeProgressTracker } from "../MakeProgressTracker/MakeProgressTracker";

interface TopBarMakeProps {
  currentStep: number;
  goToStep: (step: number) => void;
}

export const TopBarMake: React.FC<TopBarMakeProps> = ({
  currentStep,
  goToStep,
}) => {
  const [target, setTarget] = useState<HTMLElement | null>(null);

  useEffect(() => {
    // Find the target div in the DOM
    const topBarCenter = document.getElementById("top-bar-center");
    setTarget(topBarCenter);
  }, []);

  // If the target div is not found, don't render anything
  if (!target) return null;

  // Use React Portal to render MakeProgressTracker inside the target div
  return ReactDOM.createPortal(
    <MakeProgressTracker currentStep={currentStep} goToStep={goToStep} />,
    target
  );
};

export default TopBarMake;
