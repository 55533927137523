// src/modules/Dashboard/components/UserDisplay/hooks/useLogoutHandler.ts

import { useCallback } from "react";
import { setupLogoutListener } from "../../../../../services/auth/authUserLogout";

export const useLogoutHandler = () => {
  return useCallback(() => {
    setupLogoutListener();
  }, []);
};
