// src/modules/Dashboard/Dashboard.tsx

import React, { useState } from "react";
import styled from "styled-components";
import { UserDisplay } from "./components/UserDisplay/UserDisplay";
import { BaseResumes } from "./components/BaseResumes/BaseResumes";
import { UserMakes } from "./components/UserMakes/UserMakes";
import { MakeModule } from "modules/Make/Make";
import { SideBar } from "./components/SideBar/SideBar";

const DashboardContainer = styled.div`
  margin-top: 0;
  padding-top: 0;
`;

const ContentContainer = styled.div<{ $sidebarWidth: number }>`
  margin-left: ${({ $sidebarWidth }) => `${$sidebarWidth}px`};
  padding: 20px;
  padding-top: 0;
  margin-top: 0;
  transition: margin-left 0.3s ease; /* Smooth transition */
`;

interface DashboardProps {
  user: any | null;
  testEmail: string | null;
}

export const Dashboard: React.FC<DashboardProps> = ({ user, testEmail }) => {
  const [selectedModule, setSelectedModule] = useState<string>("makes");
  const [isSidebarExpanded, setIsSidebarExpanded] = useState<boolean>(false);

  const collapsedWidth = 60; // Width when sidebar is collapsed
  const expandedWidth = 250; // Width when sidebar is expanded
  const sidebarWidth = isSidebarExpanded ? expandedWidth : collapsedWidth;

  const handleSelectModule = (module: string) => {
    setSelectedModule(module);
  };

  const renderModule = () => {
    switch (selectedModule) {
      case "makes":
        return <UserMakes />;
      case "baseResumes":
        return <BaseResumes />;
      case "make":
        return <MakeModule />;
      default:
        return <UserMakes />;
    }
  };

  return (
    <DashboardContainer>
      <SideBar
        onSelect={handleSelectModule}
        selectedModule={selectedModule}
        sidebarWidth={sidebarWidth}
        onMouseEnter={() => setIsSidebarExpanded(true)}
        onMouseLeave={() => setIsSidebarExpanded(false)}
      />
      <ContentContainer $sidebarWidth={sidebarWidth}>
        <UserDisplay user={user} testEmail={testEmail} />
        {renderModule()}
      </ContentContainer>
    </DashboardContainer>
  );
};
