// src/theme/index.ts

import colors from "./colors";
import fonts from "./fonts";
import spacing from "./spacing";
import shadows from "./shadows";

const theme = {
  colors,
  fonts,
  spacing,
  shadows,
  borderRadius: {
    small: "4px",
    medium: "8px",
    large: "30px",
    // etc.
  },
  // Add other design tokens as needed
};

export default theme;
