// src/modules/Dashboard/components/SetupResume/hooks/useResumeForm.ts

import { useState } from "react";

interface FormData {
  gDocURL: string;
  baseResumeName: string;
}

export const useResumeForm = () => {
  const [formData, setFormData] = useState<FormData>({
    gDocURL: "",
    baseResumeName: "",
  });
  const [statusMessage, setStatusMessage] = useState("");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return {
    formData,
    statusMessage,
    setStatusMessage,
    handleInputChange,
  };
};
