// src/modules/Dashboard/components/SetupResume/SetupResume.tsx

import React, { useRef } from "react";
import { useAuth } from "./hooks/useAuth";
import { config } from "config";
import { useResumeForm } from "./hooks/useResumeForm";
import { submitResumeSetup } from "./services/resumeService";
import * as Primitive from "../../../../components/primitives";
import {
  SetupResumeContainer,
  SeupResumeBackdrop,
  SetupResumeHeader,
  SetupResumeForm,
  SetupResumeInput,
  SetupResumeLabel,
  SetupResumeStatusMessage,
  SetupResumeButton,
  SubmitButton,
  SetupResumeButtonLarge,
} from "./SetupResume.styled";
import { GooglePicker } from "@components/GooglePicker/GooglePicker";
import { GoogleDriveSVG } from "assets/GoogleDriveSVG";

interface SetupResumeProps {
  onClose: () => void;
}

export const SetupResume: React.FC<SetupResumeProps> = ({ onClose }) => {
  const { user } = useAuth();
  const { formData, statusMessage, setStatusMessage, handleInputChange } =
    useResumeForm();

  const handleFormSubmission = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!user) {
      console.error("User is not authenticated.");
      return;
    }

    if (!user.email) {
      console.error("User email is required.");
      setStatusMessage("Error: User email is required.");
      return;
    }

    const userData = {
      gDocURL: formData.gDocURL,
      baseResumeName: formData.baseResumeName,
      userId: user.uid,
      userEmail: user.email,
      processCount: 1,
    };

    try {
      const data = await submitResumeSetup(userData);

      if (data.isPublic) {
        setStatusMessage(
          "Resume submitted. (Refresh dashboard page in 30-60s to view.)"
        );
      } else {
        setStatusMessage(
          "Error: submitted link is not publicly viewable. Update the document's sharing/visibility settings."
        );
      }
    } catch (error) {
      console.error("Error during form submission:", error);
      setStatusMessage("Error. Resume not submitted.");
    }
  };

  /* --- PICKER LOGIC --- */

  const accessTokenRef = useRef<string | null>(null);

  const handlePickerChange = (data: any) => {
    console.log("Picker data:", data);
  };

  // Correctly define onAuthenticate
  const onAuthenticate = (token: string) => {
    console.log("Authenticated with token:", token);
    accessTokenRef.current = token;
  };

  return (
    <SeupResumeBackdrop onClick={onClose}>
      <SetupResumeContainer onClick={(e) => e.stopPropagation()}>
        <SetupResumeHeader>Setup Resume</SetupResumeHeader>
        <SetupResumeForm onSubmit={handleFormSubmission}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <SetupResumeLabel htmlFor="baseResumeName">
              Base Resume Name
            </SetupResumeLabel>
            <SetupResumeInput
              type="text"
              id="baseResumeName"
              name="baseResumeName"
              placeholder="Untitled"
              value={formData.baseResumeName}
              onChange={handleInputChange}
            />

            <GooglePicker
              clientId={config.googlePicker.clientId}
              developerKey={config.googlePicker.apiKey}
              scope={[
                // "https://www.googleapis.com/auth/drive.readonly",
                "https://www.googleapis.com/auth/drive.metadata.readonly",
                "https://www.googleapis.com/auth/drive.file",
                // "https://www.googleapis.com/auth/documents.readonly",
              ]}
              onAuthenticate={onAuthenticate} // Correctly pass the function
              onChange={handlePickerChange}
              onAuthFailed={(data) => console.log("on auth failed:", data)}
              multiselect={true}
              navHidden={true}
              mimeTypes={["application/vnd.google-apps.document"]}
              query={"resume"}
              viewId={"DOCS"}
            >
              <SetupResumeButtonLarge>
                <Primitive.SVGWrapper
                  $width="64px"
                  $height="64px"
                  $padding="8px"
                >
                  <GoogleDriveSVG />
                </Primitive.SVGWrapper>
                Select Google Doc
              </SetupResumeButtonLarge>
            </GooglePicker>

            {/* <SetupResumeLabel htmlFor="gDocURL">
            Paste Google Doc URL
          </SetupResumeLabel>
          <SetupResumeInput
            type="url"
            id="gDocURL"
            name="gDocURL"
            value={formData.gDocURL}
            onChange={handleInputChange}
          /> */}

            <SubmitButton type="submit">Import</SubmitButton>
          </div>
        </SetupResumeForm>

        {/* <SetupResumeStatusMessage>{statusMessage}</SetupResumeStatusMessage> */}
        <SetupResumeButton onClick={onClose}>Close</SetupResumeButton>
      </SetupResumeContainer>
    </SeupResumeBackdrop>
  );
};
