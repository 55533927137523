// src/theme/colors.ts

const colors = {
  secondary: "#6c757d",
  white: "#ffffff",
  black: "#000000",
  grayLight: "#f0f0f0",
  grayDark: "#e0e0e0",
  text: "#333333",
  buttonPrimary: "#3554d1",
  buttonText: "#ffffff",
  // Add more colors as needed
};

export default colors;
