// src/components/DropdownMenu.tsx

import React from "react";
import styled from "styled-components";
import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";
import { ChevronRight } from "@mui/icons-material";
import {
  DropdownItem,
  DropdownContent,
  DropdownSeparator,
} from "./DropdownMenu.styled";
import { Submenu } from "./SubMenu";
import useResponsive from "../../hooks/useResponsive";
import { isTouchDevice } from "../../utils/isTouchDevice";

export interface DropdownMenuItem {
  id?: string;
  label?: string;
  onSelect?: () => void;
  icon?: React.ReactNode;
  groupLabel?: string;
  items?: DropdownMenuItem[];
  keepOpen?: boolean;
  separator?: boolean;
}

interface DropdownMenuProps {
  trigger: React.ReactNode;
  items: DropdownMenuItem[];
  sideOffset?: number;
  align?: "start" | "center" | "end";
}

export const DropdownMenu: React.FC<DropdownMenuProps> = ({
  trigger,
  items,
  sideOffset = 5,
  align = "end",
}) => {
  const isMobile = useResponsive();

  return (
    <DropdownMenuPrimitive.Root>
      <DropdownMenuPrimitive.Trigger asChild>
        {trigger}
      </DropdownMenuPrimitive.Trigger>
      <DropdownMenuPrimitive.Portal>
        <DropdownContent
          sideOffset={sideOffset}
          align={align}
          $isMobile={isMobile}
        >
          {items.map((item, index) => {
            if (item.separator) {
              return (
                <DropdownSeparator
                  key={`separator-${index}`}
                  $isMobile={isMobile}
                />
              );
            } else if (item.groupLabel && item.items && item.id) {
              return <Submenu item={item} key={item.id} />;
            } else if (item.label) {
              return (
                <DropdownItem
                  key={index}
                  onSelect={(event: any) => {
                    if (item.keepOpen) {
                      event.preventDefault();
                    }
                    item.onSelect?.();
                  }}
                  $isMobile={isMobile}
                >
                  {item.icon && item.icon}
                  {item.label}
                </DropdownItem>
              );
            } else {
              return null;
            }
          })}
        </DropdownContent>
      </DropdownMenuPrimitive.Portal>
    </DropdownMenuPrimitive.Root>
  );
};
