// src/modules/Dashboard/components/UserMakes/UserMakesTable.tsx

import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import * as Primitive from "components/primitives";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import {
  UserMakesTableHeader,
  UserMakesDateHeaderRow,
  UserMakesTableData,
  UserMakesTableRow,
  DeleteMakeButton,
  MakeCoverLetterButton,
  UserMakesSVGWrapper,
  UserMakesDateHeaderRowData,
} from "./UserMakes.styled";
import ItemMenuMakes from "./ItemMenuMakes";
import { PDFSVG } from "assets/PDFSVG";
import { GoogleDriveSVG } from "assets/GoogleDriveSVG";
import {
  MoreVert as MoreVertIcon,
  Delete as DeleteIcon,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";

// Styled Components (unchanged)
export const PDFIcon = styled.div`
  background-color: #ff2116;
  background-size: cover;
  width: 24px;
  height: 24px;
  border-radius: 20%;
`;

export const PDFIconText = styled.span`
  color: white;
  font-size: 0.6rem;
  font-weight: bold;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  text-decoration: none !important;
  height: 100%;
`;

// Styled Components for Dropdown Menu
const DropdownTrigger = styled(DropdownMenu.Trigger)`
  padding: 8px 12px;
  background-color: #f0f0f0;
  border: 1px solid #c0c0c0;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 120px;

  &:hover {
    background-color: #e0e0e0;
  }
`;

const DropdownContent = styled(DropdownMenu.Content)`
  min-width: 160px;
  background-color: white;
  border: 1px solid #c0c0c0;
  border-radius: 4px;
  padding: 4px 0;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
`;

const DropdownItem = styled(DropdownMenu.Item)`
  padding: 8px 12px;
  cursor: pointer;
  &:hover {
    background-color: #f5f5f5;
  }
`;

export interface UserMake {
  id: string;
  makeDateTimeUNIX: string;
  makeTitle: string;
  makeJobPostURL: string;
  makeFileIDMakeFolder: string;
  makeFileIDMakeResumePDF: string;
  makeStatus: string;
}

interface UserMakesTableProps {
  makes: UserMake[];
  onDelete: (docId: string) => void;
  onMakeCoverLetter: (docId: string) => void;
  isDeleting: { [key: string]: boolean };
  isMakingCoverLetter: { [key: string]: boolean };
}

export const UserMakesTable: React.FC<UserMakesTableProps> = ({
  makes,
  onDelete,
  onMakeCoverLetter,
  isDeleting,
  isMakingCoverLetter,
}) => {
  // **Always call hooks**, regardless of conditions
  // 1. Group makes by date
  const groupedMakes = useMemo(() => {
    return [...makes]
      .sort((a, b) => Number(b.makeDateTimeUNIX) - Number(a.makeDateTimeUNIX))
      .reduce(
        (acc, make) => {
          const currentDate = new Date(Number(make.makeDateTimeUNIX));
          const dayKey = currentDate.toDateString();
          if (!acc[dayKey]) {
            acc[dayKey] = [];
          }
          acc[dayKey].push(make);
          return acc;
        },
        {} as { [date: string]: UserMake[] }
      );
  }, [makes]);

  // 2. Initialize expanded state
  const [expandedDates, setExpandedDates] = useState<{
    [date: string]: boolean;
  }>({});

  useEffect(() => {
    const initialExpanded: { [date: string]: boolean } = {};
    Object.keys(groupedMakes).forEach((date) => {
      initialExpanded[date] = true; // All expanded by default
    });
    setExpandedDates(initialExpanded);
  }, [groupedMakes]);

  // 3. Toggle function
  const toggleDate = (date: string) => {
    setExpandedDates((prev) => ({
      ...prev,
      [date]: !prev[date],
    }));
  };

  // State for statuses
  const [statuses, setStatuses] = useState<{ [id: string]: string }>({});

  // Initialize statuses when makes change
  useEffect(() => {
    const initialStatuses: { [id: string]: string } = {};
    makes.forEach((make) => {
      initialStatuses[make.id] = make.makeStatus;
    });
    setStatuses(initialStatuses);
  }, [makes]);

  // Placeholder function for handling status change
  const handleStatusChange = (id: string, newStatus: string) => {
    // Update the status in state
    setStatuses((prev) => ({ ...prev, [id]: newStatus }));
    console.log(`Change status of ${id} to ${newStatus}`);
  };

  // 4. Render the table
  return (
    <>
      {makes.length === 0 ? (
        // **Conditional Rendering** inside JSX
        <UserMakesTableRow>
          <UserMakesTableData colSpan={7}>No makes found.</UserMakesTableData>
        </UserMakesTableRow>
      ) : (
        Object.entries(groupedMakes).map(([dayKey, makesForDate]) => {
          const currentDate = new Date(dayKey);
          const formattedDate = currentDate
            .toLocaleDateString("en-US", {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
            })
            .toUpperCase();
          const isExpanded = expandedDates[dayKey];

          return (
            <React.Fragment key={`group-${dayKey}`}>
              {/* Date Header Row with Toggle */}
              <UserMakesDateHeaderRow
                onClick={() => toggleDate(dayKey)}
                style={{ cursor: "pointer" }}
                role="button"
                aria-expanded={isExpanded}
              >
                <UserMakesDateHeaderRowData colSpan={6}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <span style={{ marginLeft: "10px" }}>{formattedDate}</span>
                    <ExpandMoreIcon
                      style={{
                        transform: isExpanded
                          ? "rotate(0deg)"
                          : "rotate(90deg)",
                        transition: "transform 0.2s",
                      }}
                    />
                  </div>
                </UserMakesDateHeaderRowData>
              </UserMakesDateHeaderRow>

              {/* Conditionally Render Make Rows */}
              {isExpanded &&
                makesForDate.map((make) => (
                  <UserMakesTableRow key={`make-${make.id}`}>
                    <UserMakesTableData>
                      {currentDate.toLocaleDateString()}
                    </UserMakesTableData>

                    {/* Title */}
                    <UserMakesTableData>
                      <Primitive.TableDataContentLeft>
                        <a
                          href={make.makeJobPostURL}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {make.makeTitle}
                        </a>
                      </Primitive.TableDataContentLeft>
                    </UserMakesTableData>

                    {/* Google Drive Link */}
                    <UserMakesTableData>
                      <a
                        href={`https://drive.google.com/drive/folders/${make.makeFileIDMakeFolder}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Primitive.TableDataContentCentered>
                          <Primitive.TableSVGButtonWrapper>
                            <UserMakesSVGWrapper>
                              <GoogleDriveSVG />
                            </UserMakesSVGWrapper>
                          </Primitive.TableSVGButtonWrapper>
                        </Primitive.TableDataContentCentered>
                      </a>
                    </UserMakesTableData>

                    {/* PDF Link */}
                    <UserMakesTableData>
                      <a
                        href={`https://drive.google.com/file/d/${make.makeFileIDMakeResumePDF}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Primitive.TableDataContentCentered>
                          <Primitive.TableSVGButtonWrapper>
                            <UserMakesSVGWrapper>
                              <PDFSVG />
                            </UserMakesSVGWrapper>
                          </Primitive.TableSVGButtonWrapper>
                        </Primitive.TableDataContentCentered>
                      </a>
                    </UserMakesTableData>

                    {/* Status Dropdown */}
                    <UserMakesTableData>
                      <DropdownMenu.Root>
                        <DropdownTrigger>
                          <span>{statuses[make.id]}</span>
                          <ExpandMoreIcon style={{ fontSize: "1rem" }} />
                        </DropdownTrigger>
                        <DropdownContent sideOffset={5}>
                          {[
                            "Ready",
                            "Submitted",
                            "Interviewing",
                            "Rejected",
                            "Accepted",
                          ].map((status) => (
                            <DropdownItem
                              key={status}
                              onSelect={() =>
                                handleStatusChange(make.id, status)
                              }
                            >
                              {status}
                            </DropdownItem>
                          ))}
                        </DropdownContent>
                      </DropdownMenu.Root>
                    </UserMakesTableData>

                    {/* Item Menu */}
                    <UserMakesTableData>
                      <ItemMenuMakes
                        trigger={
                          <Primitive.TableDataContentCentered>
                            <Primitive.TableSVGButtonWrapper>
                              <UserMakesSVGWrapper>
                                <MoreVertIcon />
                              </UserMakesSVGWrapper>
                            </Primitive.TableSVGButtonWrapper>
                          </Primitive.TableDataContentCentered>
                        }
                        sideOffset={8}
                        align="end"
                        onMakeCoverLetter={() => onMakeCoverLetter(make.id)}
                        onDelete={() => onDelete(make.id)}
                      />
                    </UserMakesTableData>
                  </UserMakesTableRow>
                ))}
            </React.Fragment>
          );
        })
      )}
    </>
  );
};
