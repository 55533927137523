// src/modules/Dashboard/components/UserDisplay/services/fetchUserData.ts

import { getFirestore, doc, getDoc } from "firebase/firestore";
import { app } from "../../../../../config/firebaseConfig";
import { FIRESTORE_PATHS } from "../../../../../config";

const db = getFirestore(app);

export const fetchUserData = async (email: string) => {
  const folderDataRef = doc(db, FIRESTORE_PATHS.userFilesFolderData(email));
  const userUsageTotalSumsRef = doc(
    db,
    FIRESTORE_PATHS.userUsageTotalSums(email)
  );

  const folderDataSnap = await getDoc(folderDataRef);
  const userUsageTotalSumsSnap = await getDoc(userUsageTotalSumsRef);

  let totalTokensUsed = 0;
  let totalCreditsUsed = 0;
  let totalCreditsRemaining = 0;

  if (userUsageTotalSumsSnap.exists()) {
    const userUsageTotalSumsData = userUsageTotalSumsSnap.data();
    totalTokensUsed = userUsageTotalSumsData?.userUsageTotalTokensSum || 0;
    totalCreditsUsed = userUsageTotalSumsData?.userUsageTotalCreditsSum || 0;
    totalCreditsRemaining =
      userUsageTotalSumsData?.userUsageTotalCreditsRemaining || 0;
  }

  return {
    folderData: folderDataSnap.exists() ? folderDataSnap.data() : null,
    totalTokensUsed,
    totalCreditsUsed,
    totalCreditsRemaining,
  };
};
