// src/modules/Make/Make.styled.tsx

import styled from "styled-components";
import * as Primitive from "../../components/primitives";
import { SVGWrapper } from "../../components/primitives/SVGWrapper.styled";

export { SVGWrapper } from "../../components/primitives/SVGWrapper.styled";

export const RamSVGWrapper = styled(SVGWrapper)`
  svg {
    width: 48px;
    height: 48px;
    fill: ${({ $fill }) => $fill || "#333"};
    /* Uncomment and customize the filter if needed */
    /* filter: invert(); */
  }
`;

export const MakeModuleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 80px; /* Adjust based on top bar height */
  height: 100vh;
  width: 100%;
  overflow: auto;
`;

export const MakeModuleTopBarContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  justify-content: center; /* Center the progress tracker */
  align-items: center;
  padding: 4px 20px;
  background-color: white; /* Ensure it overlays content */
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.5);
  z-index: 100; /* Ensure it stays above other elements */
`;

export const MakeSubmoduleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const MakeComponentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const MakeModuleHeader = styled.h1`
  margin-bottom: 10px;
`;

export const MakeSubmoduleHeader = styled.h2`
  margin-bottom: 10px;
`;

export const Button = styled.button`
  font-family: Lato, sans-serif;
`;

export const MakeButton = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  margin: 6px 0;
  background-color: rgb(67, 103, 239);
  padding: 10px 20px;
  border: none;
  color: white;
  border-radius: 4px;
  font-size: 0.85rem;
  font-weight: 500;
  letter-spacing: 0.5px;
  transition: filter 0.3s ease;
  max-width: max-content;

  &:hover {
    filter: brightness(0.75);
  }
`;

export const MakeButtonLarge = styled(Button)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 120px;
  margin: 10px 0;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #aaa;
  background-color: #eee;
  font-size: 0.9rem;
  font-weight: 550;
  transition:
    background-color 0.3s ease,
    transform 0.2s ease;

  &:hover {
    background-color: #ddd;
    transform: scale(1.03); /* Optional: add a slight zoom effect */
  }
`;

export const MakeTextArea = styled.textarea`
  width: calc(100vw - 80px);
  max-width: 800px;
  // min-width: 500px;
  min-height: 300px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-family: Lato, sans-serif;
  resize: none;
`;

export const MakeInput = styled.input`
  min-width: 480px;
  margin: 6px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-family: Lato, sans-serif;
`;
