// src/modules/Dashboard/components/SideBar/SideBar.tsx

import React from "react";
import styled from "styled-components";
import * as Primitive from "components/primitives";
import {
  SidebarContainer,
  MenuItem,
  IconWrapper,
  SeparatorContainer,
  Separator,
} from "./SideBar.styled";
import { GoogleDriveSVG } from "assets/GoogleDriveSVG";
import {
  AutoAwesomeTwoTone as AutoAwesomeIcon,
  Dashboard as DashboardIcon,
  SpaceDashboardTwoTone as SpaceDashboardIcon,
  AssignmentTwoTone as AssignmentIcon,
  Build as BuildIcon,
  Add as AddIcon,
  InstallDesktop as InstallIcon,
  ExtensionOutlined as ExtensionIcon,
  AddShoppingCartTwoTone as ShoppingCartIcon,
  OpenInNew as OpenInNewIcon,
} from "@mui/icons-material";

interface SideBarProps {
  onSelect: (module: string) => void;
  selectedModule: string;
  sidebarWidth: number;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
}

export const SideBar: React.FC<SideBarProps> = ({
  onSelect,
  selectedModule,
  sidebarWidth,
  onMouseEnter,
  onMouseLeave,
}) => {
  // Handler to open Google Drive in a new tab
  const handleOpenGoogleDrive = () => {
    window.open("https://drive.google.com", "_blank", "noopener,noreferrer");
  };

  // Handler to open Add Credits in a new tab
  const handleOpenAddCredits = () => {
    window.open("https://demolp.s-kode.com", "_blank", "noopener,noreferrer");
  };

  // Handler to open Install Extension in a new tab
  const handleOpenInstallExtension = () => {
    window.open(
      "https://chromewebstore.google.com/detail/autocv/micjohkmnpfkcnfoifijfdffpcajjiod",
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    <SidebarContainer
      $sidebarWidth={sidebarWidth}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div style={{ height: "10px" }}></div>

      {/* Make */}
      <MenuItem
        $active={selectedModule === "make"}
        onClick={() => onSelect("make")}
        $sidebarWidth={sidebarWidth}
      >
        <IconWrapper>
          {/* <BuildIcon /> */}
          <AutoAwesomeIcon />
        </IconWrapper>
        <span>Make</span>
      </MenuItem>

      <SeparatorContainer>
        <Separator />
      </SeparatorContainer>

      {/* Makes */}
      <MenuItem
        $active={selectedModule === "makes"}
        onClick={() => onSelect("makes")}
        $sidebarWidth={sidebarWidth}
      >
        <IconWrapper>
          {/* <DashboardIcon /> */}
          <SpaceDashboardIcon />
        </IconWrapper>
        <span>Dashboard</span>
      </MenuItem>

      {/* Base Resumes */}
      <MenuItem
        $active={selectedModule === "baseResumes"}
        onClick={() => onSelect("baseResumes")}
        $sidebarWidth={sidebarWidth}
      >
        <IconWrapper>
          <AssignmentIcon />
        </IconWrapper>
        <span>Base Resumes</span>
      </MenuItem>

      <SeparatorContainer>
        <Separator />
      </SeparatorContainer>

      {/* All Makes */}
      <MenuItem
        $active={false} // This item does not affect selectedModule
        onClick={handleOpenGoogleDrive}
        $sidebarWidth={sidebarWidth}
      >
        <IconWrapper>
          <div
            style={{
              display: "flex",
              scale: "0.25",
              maxHeight: "24px",
              transform: "translateY(-24px)",
            }}
          >
            <GoogleDriveSVG />
          </div>
        </IconWrapper>
        <span>Google Drive</span>
        <OpenInNewIcon style={{ marginLeft: "auto", fontSize: "16px" }} />
      </MenuItem>

      {/* Install Extension */}
      <MenuItem
        $active={false} // This item does not affect selectedModule
        onClick={handleOpenInstallExtension}
        $sidebarWidth={sidebarWidth}
      >
        <IconWrapper>
          <InstallIcon />
        </IconWrapper>
        <span>Install Extension</span>
        <OpenInNewIcon style={{ marginLeft: "auto", fontSize: "16px" }} />
      </MenuItem>

      {/* Add Credits */}
      <MenuItem
        $active={false} // This item does not affect selectedModule
        onClick={handleOpenAddCredits}
        $sidebarWidth={sidebarWidth}
      >
        <IconWrapper>
          <AddIcon />
        </IconWrapper>
        <span>Add Credits</span>
        <OpenInNewIcon style={{ marginLeft: "auto", fontSize: "16px" }} />
      </MenuItem>
    </SidebarContainer>
  );
};
