// src/modules/Dashboard/components/UserMakes/UserMakes.styled.tsx

import React from "react";
import styled from "styled-components";
import * as Primitive from "../../../../components/primitives";

// Styled Components

export const UserMakesContainer = styled(Primitive.ModuleContainer)`
  // display: flex;
  width: 100%;
  // align-items: center;
  overflow-x: scroll;
  display: flex;
  justify-content: center;
`;

export const UserMakesHeader = styled(Primitive.ModuleHeader)`
  /* Styles */
`;

export const UserMakesStyledTable = styled(Primitive.Table)`
  /* Styles */
`;

export const UserMakesTableHeader = styled(Primitive.TableHeader)`
  /* Styles */
`;

export const UserMakesTableRow = styled(Primitive.TableRow)`
  /* Styles */
`;

export const UserMakesTableData = styled(Primitive.TableData)`
  /* Styles */
`;

export const UserMakesDateHeaderRow = styled.tr`
  color: #777;
  font-size: 0.75rem;
  // font-weight: bold;
  text-align: center;
  cursor: pointer;
  transition:
    background-color 0.3s ease,
    color 0.3s ease;

  &:hover {
    filter: brightness(0.9);
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  }
`;

export const UserMakesDateHeaderRowData = styled(Primitive.TableData)`
  background-color: ${({ theme }) => theme.colors.grayLight};
`;

export const MakeCoverLetterButton = styled(Primitive.TableButton)`
  background: "#28a745";

  &:hover {
    background: "#218838";
  }
`;

export const DeleteMakeButton = styled(Primitive.TableButton)`
  background: "#dc3545";

  &:hover {
    background: "#c82333";
  }
`;

export const AllUserMakesButtonContainer = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`;

export const AllUserMakesButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  padding: 10px 20px;
  background: #eee;
  color: #000000;
  border: solid 1px #ccc;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  max-width: 100%;
  transition: filter 0.3s ease;

  &:hover {
    filter: brightness(0.9);
  }

  @media (max-width: 768px) {
    width: 100%;
    max-width: 300px;
    margin: 20px auto;
  }
`;

/* export const UserMakesButtonIcon = styled.img`
  width: auto;
  height: 24px;
  vertical-align: middle;
  padding-right: 8px;
`; */

export const UserMakesSVGWrapper = styled(Primitive.TableSVGWrapper)<{
  size?: number;
}>`
  text-decoration: none;
`;
