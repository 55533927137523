// src/hooks/useResponsive.ts

import { useState, useEffect } from "react";

const useResponsive = (
  breakpoint: number = 768,
  delay: number = 100
): boolean => {
  const [isMobile, setIsMobile] = useState<boolean>(
    window.innerWidth < breakpoint
  );

  useEffect(() => {
    let timer: NodeJS.Timeout;
    const handleResize = () => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        setIsMobile(window.innerWidth < breakpoint);
      }, delay);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      clearTimeout(timer);
      window.removeEventListener("resize", handleResize);
    };
  }, [breakpoint, delay]);

  return isMobile;
};

export default useResponsive;
