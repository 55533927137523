// src/modules/Dashboard/components/BaseResumes/BaseResumesTable.tsx

import React from "react";
import styled from "styled-components";
import * as Primitive from "../../../../components/primitives";
import {
  BaseResumesTableRow,
  BaseResumesTableData,
  // Removed BaseResumesDefaultCheck as it's no longer needed
  // BaseResumesDefaultCheck,
  // BaseResumesStyledIcon,
  // SetDefaultButton,
  DeleteButton,
  BaseResumesSVGWrapper,
} from "./BaseResumes.styled";
import ItemMenuBaseResumes from "./ItemMenuBaseResumes";
import { GoogleDocSVG } from "assets/GoogleDocSVG";
import {
  MoreVert as MoreVertIcon,
  Delete as DeleteIcon,
  CheckCircle as CheckCircleIcon,
  RadioButtonUnchecked as RadioButtonUncheckedIcon,
  AccessTimeFilledRounded as AccessTimeFilledRoundedIcon,
} from "@mui/icons-material";

export const DefaultResumeCheckContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 24px;
  background: none;

  &:hover {
    cursor: pointer;
    filter: brightness(0.8);
  }

  svg {
    &:hover {
      cursor: pointer;
      fill: green;
    }
  }
`;

interface UserBaseResume {
  id: string;
  baseResumeName: string;
  baseResumeFileIDGDoc: string;
  makeDateTimeUNIX: string;
  makeStatus: string;
  isDefault: boolean;
}

interface BaseResumesTableProps {
  makes: UserBaseResume[];
  onDelete: (id: string) => void;
  onSetDefault: (id: string) => void;
  isDeleting: { [key: string]: boolean };
  isSettingDefault: { [key: string]: boolean };
}

export const BaseResumesTable: React.FC<BaseResumesTableProps> = ({
  makes,
  onDelete,
  onSetDefault,
  isDeleting,
  isSettingDefault,
}) => {
  console.log("Rendering ResumeTable with resumes:", makes);

  if (makes.length === 0) {
    console.log("No base resumes found.");
    return (
      <BaseResumesTableRow>
        <BaseResumesTableData colSpan={7}>
          No base resumes found.
        </BaseResumesTableData>
      </BaseResumesTableRow>
    );
  }

  return (
    <>
      {makes.map((resume) => {
        console.log("Rendering resume row for:", resume.id);
        const resumeDate = new Date(parseInt(resume.makeDateTimeUNIX, 10));
        const formattedDate = resumeDate.toLocaleDateString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        });

        return (
          <BaseResumesTableRow key={resume.id}>
            {/* Date */}
            <BaseResumesTableData>{formattedDate}</BaseResumesTableData>

            {/* Name */}
            <BaseResumesTableData>
              {resume.baseResumeName ? (
                resume.baseResumeName
              ) : (
                <span style={{ color: "grey", fontStyle: "italic" }}>
                  Untitled
                </span>
              )}
            </BaseResumesTableData>

            {/* Google Doc */}
            <BaseResumesTableData>
              <a
                href={`https://docs.google.com/document/d/${resume.baseResumeFileIDGDoc}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Primitive.TableDataContentCentered>
                  <Primitive.TableSVGButtonWrapper>
                    <BaseResumesSVGWrapper>
                      <GoogleDocSVG />
                    </BaseResumesSVGWrapper>
                  </Primitive.TableSVGButtonWrapper>
                </Primitive.TableDataContentCentered>
              </a>
            </BaseResumesTableData>

            {/* Set Default */}
            <BaseResumesTableData>
              <DefaultResumeCheckContainer
                onClick={() => onSetDefault(resume.id)}
                aria-label={
                  resume.isDefault ? "Default Resume" : "Set as Default Resume"
                }
                title={
                  resume.isDefault
                    ? "This is the default resume"
                    : "Click to set as default resume"
                }
              >
                {resume.isDefault ? (
                  <CheckCircleIcon style={{ color: "green" }} />
                ) : (
                  <RadioButtonUncheckedIcon />
                )}
              </DefaultResumeCheckContainer>
            </BaseResumesTableData>

            {/* Status */}
            {/* <BaseResumesTableData>{resume.makeStatus}</BaseResumesTableData> */}

            {/* Delete */}
            {/* <BaseResumesTableData>
              <Primitive.TableDataContentCentered>
                <Primitive.TableSVGButtonWrapper>
                  <Primitive.TableSVGWrapper>
                    {isDeleting[resume.id] ? (
                      <AccessTimeFilledRoundedIcon style={{ fill: "grey" }} />
                    ) : (
                      <DeleteIcon
                        style={{ fill: "grey" }}
                        onClick={() => onDelete(resume.id)}
                        aria-label="Delete Resume"
                      />
                    )}
                  </Primitive.TableSVGWrapper>
                </Primitive.TableSVGButtonWrapper>
              </Primitive.TableDataContentCentered> 
              </BaseResumesTableData>
              */}

            {/* Item Menu */}
            <BaseResumesTableData>
              <ItemMenuBaseResumes
                trigger={
                  <Primitive.TableDataContentCentered>
                    <Primitive.TableSVGButtonWrapper>
                      <BaseResumesSVGWrapper>
                        <MoreVertIcon />
                      </BaseResumesSVGWrapper>
                    </Primitive.TableSVGButtonWrapper>
                  </Primitive.TableDataContentCentered>
                }
                sideOffset={8}
                align="end"
                onSetDefault={() => onSetDefault(resume.id)}
                onDelete={() => onDelete(resume.id)}
              />
            </BaseResumesTableData>
          </BaseResumesTableRow>
        );
      })}
    </>
  );
};
