// src/modules/Dashboard/components/BaseResumes/hooks/useWebhooks.ts

import { useContext } from "react";
import AppContext from "../../../../../contexts/AppContext";
import { WEBHOOKS } from "../../../../../config";

export const useWebhooks = () => {
  const { userStore } = useContext(AppContext);

  const getUserEmail = (): string | null => {
    return userStore.email || userStore.testEmail || null;
  };

  const sendUpdateDefaultResumeWebhook = async (
    docId: string,
    p0: any
  ): Promise<boolean> => {
    console.log(
      "Sending Set Default Resume webhook for docId:",
      docId,
      "and user:",
      getUserEmail()
    );
    const webhookUrl = WEBHOOKS.UPDATE_DEFAULT_RESUME;
    const userEmail = getUserEmail();

    if (!userEmail) {
      console.error("User email is not available. Cannot send webhook.");
      return false;
    }

    if (!webhookUrl) {
      console.error(
        "Webhook URL is not defined. Please set REACT_APP_WEBHOOK_UPDATE_DEFAULT_RESUME in your .env file."
      );
      return false;
    }

    try {
      const response = await fetch(webhookUrl, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ docId, userEmail: userEmail }),
      });

      if (response.ok) {
        console.log("Set Default Resume webhook sent successfully.");
        return true;
      }
      console.error(
        "Failed to send Set Default Resume webhook. Status:",
        response.status
      );
      return false;
    } catch (error) {
      console.error("Error sending Set Default Resume webhook:", error);
      return false;
    }
  };

  const sendDeleteWebhook = async (
    docId: string,
    email: string | null
  ): Promise<boolean> => {
    const webhookUrl = WEBHOOKS.DELETE_BASE_RESUME;
    const userEmail = getUserEmail();

    if (!userEmail) {
      console.error("User email is not available. Cannot send webhook.");
      return false;
    }

    if (!webhookUrl) {
      console.error(
        "Webhook URL is not defined. Please set REACT_APP_WEBHOOK_MAKE_DELETE in your .env file."
      );
      return false;
    }

    try {
      const response = await fetch(webhookUrl, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ docId, userEmail: userEmail }),
      });

      return response.ok;
    } catch (error) {
      console.error("Error sending delete webhook:", error);
      return false;
    }
  };

  return { sendUpdateDefaultResumeWebhook, sendDeleteWebhook };
};
