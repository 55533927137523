// src/modules/Make/Make.tsx

import React, { useState, useContext, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { MakeModuleContainer } from "./Make.styled";
import {
  MakeInputResume,
  TryInputJobDescription,
  TryResults,
} from "./components";
import { RamSVG } from "assets/ram_0314";
import { RunoIconSVG } from "assets/RunoIconSVG";
import AppContext from "../../contexts/AppContext";
import { TopBarMake } from "./components/TopBarMake/TopBarMake";

interface MakeModuleProps {
  /* Props */
}

export const MakeModule: React.FC<MakeModuleProps> = observer(() => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const { tryStore } = useContext(AppContext);

  // Persist state whenever currentStep changes
  useEffect(() => {
    tryStore.persistState();
  }, [currentStep, tryStore]);

  // Handlers to navigate steps
  const handleNext = () => setCurrentStep((prev) => Math.min(prev + 1, 2));
  const handleBack = () => setCurrentStep((prev) => Math.max(prev - 1, 0));
  const goToStep = (step: number) => setCurrentStep(step);

  return (
    <div>
      <MakeModuleContainer>
        {/* Top Bar */}
        <TopBarMake currentStep={currentStep} goToStep={goToStep} />
        {/* Steps */}
        {currentStep === 0 && <MakeInputResume onNext={handleNext} />}
        {currentStep === 1 && (
          <TryInputJobDescription onNext={handleNext} onBack={handleBack} />
        )}
        {currentStep === 2 && <TryResults onBack={handleBack} />}
      </MakeModuleContainer>
    </div>
  );
});
