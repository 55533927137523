// src/App.tsx

import React, { useEffect, useState, useContext } from "react";
import { getAuth, onAuthStateChanged, User } from "firebase/auth";
import { initializeAuth, handleRedirectResult } from "./services/auth/auth";
import { app } from "./config/firebaseConfig";
import AppContext from "./contexts/AppContext";
import { Dashboard } from "./modules/Dashboard/Dashboard";
import SignIn from "./modules/SignIn/SignIn";
import { TopBar } from "./components/TopBar/TopBar";
import { MainContainer } from "./App.styled";
import { observer } from "mobx-react-lite"; // Import observer
import { ToastContainer } from "react-toastify";

const auth = getAuth(app);

const App: React.FC = observer(() => {
  const { userStore } = useContext(AppContext);

  // Remove local state for testEmail
  // const [testEmail, setTestEmail] = useState<string | null>(null);

  const getTestEmailFromURL = (): string | null => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get("email");
  };

  useEffect(() => {
    const initialize = async () => {
      await initializeAuth();
      await handleRedirectResult();

      const emailFromURL = getTestEmailFromURL();
      userStore.setTestEmail(emailFromURL);

      if (!emailFromURL) {
        const unsubscribe = onAuthStateChanged(auth, (authUser) => {
          userStore.setUser(authUser);
          console.log(`User is logged in as: ${authUser?.email}`);
          userStore.setLoading(false);
        });

        return () => unsubscribe();
      } else {
        userStore.setLoading(false);
      }
    };

    initialize();
  }, []);

  if (userStore.loading) {
    return (
      <div
        style={{
          width: "100%",
          height: "calc(100vh - 56px",
          display: "flex",
          // alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        Loading...
      </div>
    );
  }

  return (
    <div>
      <TopBar />
      <MainContainer>
        {userStore.isAuthenticated ? (
          <Dashboard user={userStore.user} testEmail={userStore.testEmail} />
        ) : (
          <SignIn />
        )}
      </MainContainer>
      <div style={{ height: "30px" }}></div>
      <div id="ram-root-profile" style={{ display: "none" }}></div>

      {/* Toast */}
      <ToastContainer
        position="top-right"
        autoClose={750}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick={true}
        rtl={false}
        pauseOnFocusLoss
        draggable={true}
        pauseOnHover={true}
        progressClassName="toast-progress"
      />
    </div>
  );
});

export default App;
