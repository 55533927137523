// src/modules/Dashboard/components/SetupResume/services/resumeService.ts

import { WEBHOOKS } from "../../../../../config";

interface UserData {
  gDocURL: string;
  baseResumeName: string;
  userId: string;
  userEmail: string | null; // Update to allow null
  processCount: number;
}

export const submitResumeSetup = async (
  userData: UserData
): Promise<{ isPublic: boolean }> => {
  const response = await fetch(WEBHOOKS.RESUME_SETUP, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(userData),
  });

  if (!response.ok) {
    throw new Error(`Webhook request failed with status ${response.status}`);
  }

  return response.json();
};
