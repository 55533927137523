// src/service/auth/authUserLogout.ts

import { getAuth, signOut } from "firebase/auth";

// Handle the logout process
export function handleLogout(): void {
  const auth = getAuth();
  signOut(auth)
    /* .then(() => {
      console.log("User signed out.");
      updateUIAfterLogout(); // Uncomment if you have a UI update function
    }) */
    .catch((error) => {
      console.error("Error signing out:", error);
    });
}

// Setup event listener for logout
export function setupLogoutListener(): void {
  const logoutButton = document.getElementById("logout");

  if (logoutButton) {
    logoutButton.addEventListener("click", handleLogout);
    // console.log("Logout button listener initialized.");
  } else {
    console.error("Logout button not found in the DOM");
  }
}
